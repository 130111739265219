export enum ENDPOINTS {
  //AUTH ENDPOINTS
  SIGN_IN = "<BASE_URL>/auth/sign-in",

  //VENDOR ENDPOINTS
  GET_ALL_SALONS = "<BASE_URL>/admin/vendor",
  GET_VENDOR_BY_ID = "<BASE_URL>/admin/vendor/<ID>",
  SET_VENDOR_ACTIVATION = "<BASE_URL>/admin/vendor/activation",
  DELETE_VENDOR = "<BASE_URL>/admin/vendor/<ID>",

  //APPS
  CREATE_NEW_APP = "<BASE_URL>/app/create-app",
  GET_ALL_APPS = "<BASE_URL>/app/all-apps",
  UPDATE_APP = "<BASE_URL>/app",
  DELETE_APP = "<BASE_URL>/app",

  //PAYMENTS
  GET_ONE_TIME_PAYMENT = "<BASE_URL>/payments/one-time",
}

export enum REQUEST_SERVICE {
  AUTH = "auth",
  ADMIN = "admin",
  APPS = "app",
  PAYMENTS = "payments",
}
