/* eslint-disable react-hooks/exhaustive-deps */
/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useEffect, useState } from "react";
import { Button } from "reactstrap";

import GARLIC_BED from "../assets/img/brand/GarlicBed.svg";

import { ReactComponent as Visa } from "../assets/img/icons/visaCard.svg";
import { ReactComponent as Master } from "../assets/img/icons/masterCard.svg";
import { FormSessionUpdatePayload } from "../common/mpgs.types";
import { useLocation } from "react-router-dom";
import {
  initTokenization,
  setErrorBorder,
  unsetErrorBorder,
  validate,
} from "../lib/tokenization";
import { getFirstNameAndLastName } from "../lib/util";

type Customer = {
  firstName: string;
  lastName: string;
  contactNumber: string;
  email?: string;
};

var newCustomer: any;
var cardName: string;

const Tokenization = () => {
  const query = new URLSearchParams(useLocation().search);
  const [initailized, setInitialized] = useState<boolean>(false);
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cardType, setCardType] = useState<
    "NOT_SUPPORTED" | "VISA" | "MASTERCARD" | null
  >(null);
  const [errors, setErrors] = useState<{ id: string; err: string } | null>(
    null
  );

  useEffect(() => {
    const initSession = initTokenization(initialized, formSessionUpdate);
    cardName = "";
    newCustomer = undefined;
    const name = query.get("name");
    const phone = query.get("phone");
    if (name && phone) {
      setCustomer({
        ...getFirstNameAndLastName(name),
        contactNumber: phone,
        email: "",
      });
    }
    return () => initSession;
  }, []);

  const initialized = () => {
    setInitialized(true);
    const PaymentSession = window.PaymentSession;
    PaymentSession.updateSessionFromForm("card");
    PaymentSession.onCardTypeChange((_, result) => {
      if (result.status === "SUPPORTED") {
        setCardType(result.scheme);
      } else if (result.status === "NOT_SUPPORTED") {
        setCardType("NOT_SUPPORTED");
      } else {
        setCardType(null);
      }
    });
    PaymentSession.setPlaceholderStyle(
      ["card.expiryYear", "card.expiryMonth"],
      { color: "gray", fontSize: "13px", fontWeight: "300" }
    );
    PaymentSession.onFocus(
      [
        "card.number",
        "card.expiryMonth",
        "card.expiryYear",
        "card.securityCode",
        "giftCard.number",
      ],
      (field, selector: string) => {
        setErrors(null);
        unsetErrorBorder(selector.split("#")[0]);
      }
    );
  };

  const formSessionUpdate = (res: FormSessionUpdatePayload) => {
    if (res.status && res.status === "ok") {
      if (res.sourceOfFunds.provided.card?.securityCode) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            status: "ok",
            sessionId: res.session.id,
            customer: newCustomer || undefined,
            cardNickName: cardName,
          })
        );
      } else {
        setIsLoading(false);
        setErrors({ id: "securityCode", err: "CVV is required!" });
        setErrorBorder("securityCode");
      }
    } else if (
      res.status === "request_timeout" ||
      "system_error" === res.status
    ) {
      timeOutError();
    }
  };

  const timeOutError = () => {
    setErrors({
      id: "system",
      err: "Session timeout! Will  automatically refresh in 2 seconds!",
    });
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const renderCardType = () => {
    if (cardType === "VISA") {
      return <Visa height={25} width={50} />;
    } else if (cardType === "MASTERCARD") {
      return <Master height={15} width={22} style={{ marginRight: "6px" }} />;
    }
    return null;
  };

  const onAddCard = async () => {
    if (customer) {
      const errors = await Object.keys(customer).filter((_key) => {
        const keyItem = customer[_key as keyof Customer];
        if (!keyItem || (keyItem && keyItem.length === 0)) {
          return true;
        }
        return false;
      });

      if (errors.length > 0) {
        setErrors({ id: errors[0], err: "Input is required!" });
        setErrorBorder(errors[0]);
        return;
      }

      if (
        !(
          customer.email &&
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(customer.email)
        )
      ) {
        setErrors({
          id: "email",
          err: "Invalid email address! Please try again.",
        });
        setErrorBorder("email");
        return;
      }

      if (customer.contactNumber.length < 10) {
        setErrors({
          id: "contactNumber",
          err: "Invalid phone number! Please try again.",
        });
        setErrorBorder("contactNumber");
        return;
      }

      newCustomer = customer;
    }
    validate(setErrors, () => {
      setIsLoading(true);
      window.PaymentSession.updateSessionFromForm("card");
    });
  };

  const onChangeCustomer = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (customer) {
      setCustomer({ ...customer, [e.target.name]: e.target.value });
    }
  };

  const onFocusCustomer = (e: React.FocusEvent<HTMLInputElement>) => {
    if (errors) {
      unsetErrorBorder(e.target.name);
      setErrors(null);
    }
  };

  const onChangeCardNickName = (e: React.FocusEvent<HTMLInputElement>) => {
    cardName = e.target.value;
  };

  const renderError = errors && <p className="card-error">{errors.err}</p>;
  const disableBtn = isLoading || !initailized;
  const btnText = isLoading ? "Please wait" : "Add card";

  const renderCustomer = () => {
    if (customer) {
      return (
        <div style={{ marginBottom: "20px" }}>
          <div className="header">
            <h4>Customer details</h4>
            <section></section>
          </div>

          <div className="names">
            <div className="input-container">
              <label>First name</label>
              <div className="text-input" id="firstName">
                <input
                  type="text"
                  name="firstName"
                  value={customer?.firstName}
                  onChange={onChangeCustomer}
                  onFocus={onFocusCustomer}
                />
              </div>
            </div>
            <div className="input-container">
              <label>Last name</label>
              <div className="text-input" id="lastName">
                <input
                  type="text"
                  name="lastName"
                  value={customer?.lastName}
                  onChange={onChangeCustomer}
                  onFocus={onFocusCustomer}
                />
              </div>
            </div>
          </div>

          <div className="input-container">
            <label>Email address</label>
            <div className="text-input" id="email">
              <input
                type="email"
                name="email"
                value={customer?.email}
                onChange={onChangeCustomer}
                onFocus={onFocusCustomer}
              />
            </div>
          </div>

          <div className="input-container">
            <label>Phone number</label>
            <div className="text-input" id="contactNumber">
              <input
                type="text"
                name="contactNumber"
                value={customer?.contactNumber}
                onChange={onChangeCustomer}
                onFocus={onFocusCustomer}
              />
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="home">
      <div className="card-container">
        <div className="tokenize">
          {renderCustomer()}
          <div className="header">
            <h4>Card details</h4>
            <section>
              <Master height={20} width={25} />
              <Visa height={30} width={50} />
            </section>
          </div>
          {/* <div className="input-container">
            <label>Card holder name</label>
            <div className="text-input" id="nameOnCard">
              <input type="text" id="cardholder-name" readOnly />
            </div>
          </div> */}

          <div className="input-container">
            <label>Card number</label>
            <div className="text-input" id="number">
              <input type="text" id="card-number" readOnly />
              <section>{renderCardType()}</section>
            </div>
          </div>

          <div className="names">
            <div className="expiry-container">
              <div className="input-container sm">
                <label>Expiry Month</label>
                <div className="text-input" id="expiryMonth">
                  <input
                    type="text"
                    id="expiry-month"
                    readOnly
                    placeholder="MM"
                  />
                </div>
              </div>
              <div className="input-container sm">
                <label>Expiry Year</label>
                <div className="text-input" id="expiryYear">
                  <input
                    type="text"
                    id="expiry-year"
                    readOnly
                    placeholder="YYYY"
                  />
                </div>
              </div>
            </div>

            <div className="input-container ">
              <label>CVV</label>
              <div className="text-input" id="securityCode">
                <input type="text" id="security-code" readOnly />
              </div>
            </div>
          </div>

          <div className="input-container">
            <label>Card nick name (optional)</label>
            <div className="text-input">
              <input
                type="text"
                name="cardNickName"
                // value={cardNickName}
                onChange={onChangeCardNickName}
                placeholder="Give any name for card"
              />
            </div>
          </div>

          {renderError}

          <Button
            block
            color="primary"
            disabled={disableBtn}
            onClick={onAddCard}
          >
            {btnText}
          </Button>
        </div>
        <div className="footer-bottom">
          <span>
            <img alt="garlicbed-icon" src={GARLIC_BED} />
            <h6>PAYMENTS</h6>
          </span>
          <p className="copy-rights">
            © 2023 Garlic Bed (PVT) LTD. All Rights Reserved.{" "}
            {window.location.host}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Tokenization;
