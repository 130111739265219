/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  NavLink as RDNavLink,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";

// reactstrap components
import {
  Collapse,
  Form,
  Input,
  InputGroupText,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  Row,
  Col,
  NavLink,
} from "reactstrap";

import GARLIC_BED from "../../assets/img/brand/GarlicBed.svg";
import { authSignOut } from "../../common/actions/auth.actions";
import routes from "../../routes";

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [collapseOpen, setCollapseOpen] = useState<boolean>();

  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };

  const closeCollapse = () => {
    setCollapseOpen(false);
  };

  const onClickSignOut = () => {
    dispatch(authSignOut(() => navigate("/")));
  };

  const renterRoutes = (prop: any, key: number) => {
    const activeClassName = location.pathname.includes(prop.path)
      ? "active"
      : "";
    return (
      <NavItem key={key}>
        <NavLink
          tag={RDNavLink}
          to={prop.layout + prop.path}
          onClick={closeCollapse}
          className={activeClassName}
        >
          <i className={prop.icon} />
          {prop.name}
        </NavLink>
      </NavItem>
    );
  };

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>

        <NavbarBrand className="pt-0">
          <img alt="logo" className="navbar-brand-img" src={GARLIC_BED} />
        </NavbarBrand>

        <Collapse navbar isOpen={collapseOpen}>
          <div className="navbar-collapse-header d-md-none">
            <Row>
              <Col className="collapse-brand" xs="6">
                <Link to="/">
                  <img alt="" src={GARLIC_BED} />
                </Link>
              </Col>
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>

          <Form className="mt-4 mb-3 d-md-none">
            <InputGroup className="input-group-rounded input-group-merge">
              <Input
                aria-label="Search"
                className="form-control-rounded form-control-prepended"
                placeholder="Search"
                type="search"
              />

              <InputGroupText>
                <span className="fa fa-search" />
              </InputGroupText>
            </InputGroup>
          </Form>

          <Nav navbar>{routes.map(renterRoutes)}</Nav>

          <hr className="my-3" />

          <h6 className="navbar-heading text-muted">Documentation</h6>

          <Nav className="mb-md-3" navbar>
            <NavItem>
              <NavLink to="https://demos.creative-tim.com/argon-dashboard-react/#/documentation/overview?ref=adr-admin-sidebar">
                <i className="ni ni-spaceship" />
                Getting started
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="https://demos.creative-tim.com/argon-dashboard-react/#/documentation/colors?ref=adr-admin-sidebar">
                <i className="ni ni-palette" />
                Foundation
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="https://demos.creative-tim.com/argon-dashboard-react/#/documentation/alerts?ref=adr-admin-sidebar">
                <i className="ni ni-ui-04" />
                Components
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink onClick={onClickSignOut}>
                <i className="ni ni-button-power" />
                Sign out
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default Sidebar;
