import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupText,
  InputGroup,
  Modal,
  UncontrolledTooltip,
  Spinner,
} from "reactstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { createNewApp } from "../../common/actions/apps.actions";

type OwnProps = {
  isOpen: boolean;
  setIsOpen: (toggle: boolean) => void;
};

const CreateNewAp = ({ setIsOpen, isOpen }: OwnProps) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [keys, setKeys] = useState<{
    privateKey: string;
    secretKey: string;
  } | null>(null);
  const [copied, setCopied] = useState<boolean>(false);
  const appName = useRef<string>("");
  const toggleModal = () => setIsOpen(!isOpen);

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) =>
    (appName.current = e.target.value);

  const onClickSubmit = () => {
    if (appName.current.length > 0) {
      setIsLoading(true);
      dispatch(
        createNewApp(appName.current, (allKeys) => {
          setKeys(allKeys || null);
          setIsLoading(false);
        })
      );
    }
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onClickSubmit();
  };

  const onPressCopy = () => setCopied(true);

  const onClickDownload = () => {
    const blob = new Blob([keys?.privateKey || ""], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "private-key.txt";
    link.href = url;
    link.click();
  };

  const copyText = copied ? "Copied" : "Copy To Clipboard";

  const renderPrivateKey = () => {
    if (keys) {
      return (
        <>
          <CopyToClipboard text={keys.secretKey} onCopy={onPressCopy}>
            <Button id="copy-private-key" type="button" className="mx-0">
              <div className="mb-1">
                <i className="ni ni-ungroup" />
                <span>Copy your secret key</span>
              </div>
              <p>{keys.secretKey}</p>
            </Button>
          </CopyToClipboard>

          <Button type="button" className="mx-0 py-4" onClick={onClickDownload}>
            <div className="mb-1">
              <i className="ni ni-cloud-download-95" />
              <span>Download private key</span>
            </div>
          </Button>

          <UncontrolledTooltip
            delay={0}
            trigger="hover focus"
            target="copy-private-key"
          >
            {copyText}
          </UncontrolledTooltip>
        </>
      );
    }

    return null;
  };

  const renderSpinner = isLoading && (
    <Spinner size="sm" color="light" className="mr-2" />
  );

  return (
    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={isOpen}
      toggle={toggleModal}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-4">
            <div className="text-muted text-center mt-2">Create new app</div>
          </CardHeader>
          <CardBody className="px-lg-5">
            <Form role="form" onSubmit={onSubmit}>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupText>
                    <i className="ni ni-app" />
                  </InputGroupText>

                  <Input
                    placeholder="App name"
                    type="text"
                    name="name"
                    onChange={onChangeName}
                  />
                </InputGroup>
              </FormGroup>

              <div className="text-center">
                <Button color="primary" type="button" onClick={onClickSubmit}>
                  {renderSpinner}
                  Create
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
      {renderPrivateKey()}
    </Modal>
  );
};

export default CreateNewAp;
