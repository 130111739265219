import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import authReducer from "./common/reducers/auth.reducer";
import commonReducer from "./common/reducers/common.reducer";
import appsReducer from "./common/reducers/apps.reducer";

const AuthPersistConfig = {
  storage,
  key: "auth",
};

const CommonPersistConfig = {
  storage,
  key: "common",
};

const AppsPersistConfig = {
  storage,
  key: "apps",
};

export default combineReducers({
  auth: persistReducer(AuthPersistConfig, authReducer),
  common: persistReducer(CommonPersistConfig, commonReducer),
  apps: persistReducer(AppsPersistConfig, appsReducer),
});
