import { request } from "../../lib/api";
import { ENDPOINTS, REQUEST_SERVICE } from "../constants";

export default class PaymentsAPI {
  static getOneTimePayment = (payload: {
    paymentIntentId: string;
    appId: string;
  }) =>
    request(
      REQUEST_SERVICE.PAYMENTS,
      ENDPOINTS.GET_ONE_TIME_PAYMENT,
      "GET",
      payload,
      false
    );
}
