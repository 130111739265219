import { Address, FeedbackType } from "../common/types";

export const getVendorStatusAndColor = (enabled: boolean, visible: boolean) => {
  if (!enabled) {
    return { status: "Disabled", color: "danger" };
  }
  if (!visible) {
    return { status: "Deactive", color: "warning" };
  }
  return { status: "Active", color: "success" };
};

export const getAddressString = (
  address: Address,
  cityOnly?: boolean,
  firstLine?: boolean
) => {
  if (address) {
    if (firstLine) {
      return address?.addressLine01 || "";
    } else {
      const city = address?.city ? address.city + ", " : "";
      const district = address?.district || "";
      if (cityOnly) {
        return city + district;
      } else {
        const line01 = address?.addressLine01
          ? address.addressLine01 + ", "
          : "";
        return line01 + city + district;
      }
    }
  } else {
    return "";
  }
};

export const getRate = (
  feedbacks: FeedbackType[]
): { outOf: number; rate: number } => {
  const filteredFeedbacks = feedbacks.filter((item) => item.rate !== 0);
  const outOf = filteredFeedbacks.length;

  if (outOf === 0) {
    return { outOf: 0, rate: 0 };
  } else {
    let cummulativeSum = 0;
    feedbacks.forEach((item) => (cummulativeSum += item.rate));

    return { outOf, rate: Number((cummulativeSum / outOf).toFixed(1)) };
  }
};

export const getFirstNameAndLastName = (fullName: string) => {
  const names = fullName.split(" ");
  const firstName = names[0];
  const lastName = names.length === 1 ? "" : names[names.length - 1];
  return { firstName, lastName };
};
