/* eslint-disable no-empty-pattern */
import { call, put, takeLeading } from "redux-saga/effects";
import { ACTIONS } from "../actions/actionTypes";
import AuthAPI from "../api/auth.api";

function* authSignIn({
  payload,
  success,
}: {
  type: typeof ACTIONS.AUTH_SIGN_IN;
  payload: { userName: string; password: string };
  success: (success: boolean) => void;
}) {
  try {
    yield put({ type: ACTIONS.SET_LOADING, payload: true });
    const data: { token: string } = yield call(AuthAPI.signIn, payload);
    yield put({ type: ACTIONS.SET_AUTH, payload: data.token });
    yield put({ type: ACTIONS.SET_LOADING, payload: false });
    success(true);
  } catch (error: any) {
    yield put({ type: ACTIONS.SET_LOADING, payload: false });
    success(false);
  }
}

function* authSignOut({
  success,
}: {
  type: typeof ACTIONS.AUTH_SIGN_OUT;
  success: (success: boolean) => void;
}) {
  yield put({ type: ACTIONS.SET_AUTH, payload: null });
  success(true);
}

export default function* authSaga() {
  yield takeLeading(ACTIONS.AUTH_SIGN_IN, authSignIn);
  yield takeLeading(ACTIONS.AUTH_SIGN_OUT, authSignOut);
}
