/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Spinner,
  NavLink,
} from "reactstrap";
import {
  deleteApp,
  getAllApps,
  updateApp,
} from "../common/actions/apps.actions";
import { AppsType, Reducers } from "../common/types";

import Header from "../components/Headers/Header.component";
import CreateNewApp from "../components/Modals/CreateNewApp.component";

const Apps = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state: Reducers) => state.common.loading);
  const apps = useSelector((state: Reducers) => state.apps.apps);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [appUrl, setAppUrl] = useState<{ index: number; url: string } | null>(
    null
  );

  useEffect(() => {
    dispatch(getAllApps());
  }, [apps.length, dispatch]);

  const onClickCreateApp = () => setIsModalOpen(true);

  const onDelete = (id: string) => {
    dispatch(deleteApp(id, () => {}));
  };

  const onUpdateApp = (index: number) => {
    const selectedApp = apps[index];
    if (appUrl?.index === index && appUrl?.url !== selectedApp.returnUrl) {
      dispatch(updateApp({ returnUrl: appUrl.url, id: selectedApp._id }));
    }
  };

  const onChangeAppUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (appUrl) {
      setAppUrl({ ...appUrl, url: e.target.value });
    }
  };

  const onFocusAppUrl = (index: number) => {
    setAppUrl({ index, url: "" });
  };

  const renderApps = (app: AppsType, index: number) => {
    const onClickDelete = () => onDelete(app._id);
    const onFocusUrl = () => onFocusAppUrl(index);
    const onClickUpdate = () => onUpdateApp(index);

    return (
      <tr key={app._id}>
        <th scope="row">{app.name}</th>
        <td>{app.appId}</td>
        <td>
          <input
            type="text"
            placeholder={app.returnUrl}
            className="mr-1"
            onChange={onChangeAppUrl}
            onFocus={onFocusUrl}
          />
          <Button
            color="success"
            type="button"
            size="sm"
            className="badge-circle small"
            onClick={onClickUpdate}
          >
            <i className="fa-solid fa-arrows-rotate"></i>
          </Button>
        </td>
        <td>
          <NavLink onClick={onClickDelete}>
            <i className="fa-regular fa-trash-can text-orange"></i>
          </NavLink>
        </td>
      </tr>
    );
  };

  if (!isLoading) {
    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="8">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">All dev apps</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        onClick={onClickCreateApp}
                        size="sm"
                      >
                        Create new app
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">App name</th>
                      <th scope="col">App Id</th>
                      <th scope="col">Return URL</th>
                      <th scope="col">Delete</th>
                    </tr>
                  </thead>
                  <tbody>{apps.map(renderApps)}</tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
        <CreateNewApp isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
      </>
    );
  }

  return (
    <>
      <Header />
      <div className="header bg-gradient-info pb-8">
        <Spinner color="light" />
      </div>
    </>
  );
};

export default Apps;
