/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import GARLIC_BED from "../assets/img/brand/GarlicBed.svg";

const Home = () => {
  return (
    <div className="home">
      <img
        src="https://garlicbed.com/LogoIcon.ccfe50e0.svg"
        alt="garlicbed-watermarkt"
        className="watermark"
      />
      <div className="home-contaienr">
        <div className="card">
          <p>
            This is the payment gateway of{" "}
            <a href="https://garlicbed.com/">Garlic Bed PVT LTD</a> and, only
            its authorities are authorized to access this payment gateway via
            <br />
            powered by
          </p>
          <a href="https://webxpay.co/">
            <img
              alt="webxpay payment gateway www.webxpay.co"
              src="https://webxpay.co/wp-content/uploads/2020/11/WebXpay-Black.C-Logo.png"
            />
          </a>
        </div>
        <span>
          <img alt="garlicbed-icon" src={GARLIC_BED} />
          <h6>PAYMENTS</h6>
        </span>
        <p className="copy-rights">
          © 2023 Garlic Bed (PVT) LTD. All Rights Reserved.
        </p>
      </div>
    </div>
  );
};

export default Home;
