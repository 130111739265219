import { FormSessionUpdatePayload } from "../common/mpgs.types";

export const initTokenization = (
  initialized: () => void,
  formSessionUpdate: (res: FormSessionUpdatePayload) => void
) => {
  if (window.PaymentSession) {
    window.PaymentSession.configure({
      fields: {
        card: {
          number: "#card-number",
          securityCode: "#security-code",
          expiryMonth: "#expiry-month",
          expiryYear: "#expiry-year",
          // nameOnCard: "#cardholder-name",
        },
      },
      frameEmbeddingMitigation: ["javascript", "csp"],
      callbacks: {
        initialized,
        formSessionUpdate,
      },
      interaction: {
        displayControl: {
          formatCard: "EMBOSSED",
          invalidFieldCharacters: "REJECT",
        },
      },
    });
  }
};

export const validate = (
  setErrors: (error: any) => void,
  onSuccess: () => void
) => {
  window.PaymentSession.validate("card", (validation) => {
    const card = validation?.card;
    if (card && !card.isValid) {
      if (!card.number.isValid || card.number.hasError) {
        setErrorBorder("number");
        if (card.number.errorReason === "NOT_SUPPORTED") {
          setErrors({
            id: "number",
            err: "Sorry! Card is not supported. VISA|Master only",
          });
        } else {
          setErrors({
            id: "number",
            err: "Card number is invalid! Please try again.",
          });
        }
        return false;
      }

      if (!card.expiryMonth.isValid || card.expiryMonth.hasError) {
        setErrorBorder("expiryMonth");
        setErrors({
          id: "expiryMonth",
          err: "Expiry month is invalid! Should be in MM format (ex: 01).",
        });
        return false;
      }

      if (!card.expiryYear.isValid || card.expiryYear.hasError) {
        setErrorBorder("expiryYear");
        setErrors({
          id: "expiryYear",
          err: "Expiry year is invalid! Should be in YYYY format (ex: 2024).",
        });
        return false;
      }

      if (!card.securityCode.isValid || card.securityCode.hasError) {
        setErrorBorder("securityCode");
        setErrors({
          id: "securityCode",
          err: "CVV number is invalid! Please try again.",
        });
        return false;
      }
    } else {
      return onSuccess();
    }
  });
};

export const setErrorBorder = (selector: string) => {
  const inputItem = document.getElementById(selector);
  if (inputItem) {
    inputItem.style.border = "1px solid red";
  }
};

export const unsetErrorBorder = (selector: string) => {
  const inputItem = document.getElementById(selector);
  if (inputItem) {
    inputItem.style.border = "1px solid #9d9d9d30";
  }
};
