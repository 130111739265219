import { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import { store } from "../store";
import { baseUrl, environment } from "../common/config";
import { REQUEST_SERVICE } from "../common/constants";

const axios = require("axios");

const configHeaders = (
  auth: boolean,
  method: AxiosRequestConfig["method"],
  customHeaders?: AxiosRequestConfig["headers"]
): AxiosRequestConfig["headers"] => {
  let headers: AxiosRequestConfig["headers"] = customHeaders || {};

  if (method !== "GET" && method !== "DELETE") {
    headers["Content-Type"] = "application/json";
  }

  if (auth) {
    headers["x_auth_token"] = store.getState().auth.token;
  }

  return headers;
};

export const request = (
  service: REQUEST_SERVICE,
  endpoint: AxiosRequestConfig["url"],
  method: AxiosRequestConfig["method"],
  requestData?: AxiosRequestConfig["data"] | AxiosRequestConfig["params"],
  auth: boolean = true,
  customHeaders?: AxiosRequestConfig["headers"]
) =>
  new Promise(async (resolve, reject) => {
    const serviceUrl = environment === "DEV" ? "stage_" + service : service;
    const url = endpoint?.replace("<BASE_URL>", baseUrl + serviceUrl);
    const params = method === "GET" ? requestData : null;
    const data = method === "GET" ? null : requestData;
    const headers = configHeaders(auth, method, customHeaders);

    // console.log(url, method, params || data, headers);

    axios({
      url,
      method,
      data,
      params,
      headers,
      timeout: 30000,
    })
      .then(async (response: AxiosResponse) => {
        resolve(response.data);
      })
      .catch(async (error: AxiosError) => {
        reject(error);
      });
  });
