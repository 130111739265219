/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import {
  Form,
  FormGroup,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Container,
} from "reactstrap";
import routes from "../../routes";

const AdminNavbar = () => {
  const location = useLocation();
  const brandText = useMemo(() => {
    const foundName = routes.find((_route) =>
      location.pathname.includes(_route.layout + _route.path)
    );

    if (foundName) {
      return foundName.name;
    }
    return "Brand";
  }, [location]);

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          {brandText}
          <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            <FormGroup className="mb-0">
              <InputGroup className="input-group-alternative">
                <InputGroupText>
                  <i className="fas fa-search" />
                </InputGroupText>

                <Input placeholder="Search" type="text" />
              </InputGroup>
            </FormGroup>
          </Form>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
