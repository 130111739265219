/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Routes } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "../components/Navbars/AdminNavbar.component";
import AdminFooter from "../components/Footers/AdminFooter.component";
import Sidebar from "../components/Sidebar/Sidebar.component";

import Index from "../views/Index.view";
import Profile from "../views/examples/Profile.view";
import Maps from "../views/examples/Maps.view";
import Tables from "../views/examples/Tables.view";
import Icons from "../views/examples/Icons.view";

import Apps from "../views/Apps.view";

const Admin = () => {
  const mainContent = React.useRef<HTMLDivElement>(null);

  return (
    <>
      <Sidebar />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar />
        <Routes>
          <Route path="apps" element={<Apps />} />
          <Route path="index" element={<Index />} />
          <Route path="icons" element={<Icons />} />
          <Route path="maps" element={<Maps />} />
          <Route path="user-profile" element={<Profile />} />
          <Route path="tables" element={<Tables />} />
        </Routes>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default Admin;
