/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupText,
  InputGroup,
  Col,
  Spinner,
} from "reactstrap";
import { authSignIn } from "../../common/actions/auth.actions";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [credentials, setCredentials] = useState<{
    password: string;
    userName: string;
  }>({ password: "", userName: "" });

  const onChangeCredentials = (e: React.ChangeEvent<HTMLInputElement>) =>
    setCredentials({ ...credentials, [e.target.name]: e.target.value });

  const onPressSignIn = () => {
    setIsLoading(true);
    dispatch(
      authSignIn(credentials, (success) => {
        if (success) {
          navigate("/admin/apps");
        }
      })
    );
  };

  const renderSpinner = isLoading && (
    <Spinner size="sm" color="light" className="mr-2" />
  );

  return (
    <Col lg="5" md="7">
      <Card className="bg-secondary shadow border-0">
        <CardBody className="px-lg-5 py-lg-5">
          <div className="text-center text-muted mb-4">
            <small>Sign in</small>
          </div>
          <Form role="form" onSubmit={onPressSignIn}>
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <InputGroupText>
                  <i className="ni ni-single-02" />
                </InputGroupText>

                <Input
                  placeholder="User name"
                  type="text"
                  onChange={onChangeCredentials}
                  name="userName"
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupText>
                  <i className="ni ni-lock-circle-open" />
                </InputGroupText>

                <Input
                  placeholder="Password"
                  type="password"
                  name="password"
                  onChange={onChangeCredentials}
                />
              </InputGroup>
            </FormGroup>
            <div className="text-center">
              <Button
                className="my-4"
                color="primary"
                type="button"
                onClick={onPressSignIn}
              >
                {renderSpinner}
                Sign in
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default Login;
