import { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody, Spinner } from "reactstrap";
import { useLocation } from "react-router-dom";

import AuthNavbar from "../components/Navbars/AuthNavbar.component";
import PaymentsAPI from "../common/api/payments.api";
import { OneTimePaymentPayload } from "../common/types";
import { oneTimeUrl } from "../common/config";

const OneTimePayments = () => {
  const query = new URLSearchParams(useLocation().search);
  const paymentIntentId = query.get("paymentIntentId");
  const appId = query.get("appId");

  const [loading, setIsLoading] = useState<boolean>(true);
  const [oneTimePayload, setOneTimePayload] =
    useState<OneTimePaymentPayload | null>(null);

  useEffect(() => {
    if (!paymentIntentId || !appId) {
      setIsLoading(false);
    } else {
      PaymentsAPI.getOneTimePayment({ paymentIntentId, appId })
        .then((res) => {
          const payload = res as OneTimePaymentPayload;
          if (payload) {
            setOneTimePayload(payload);
            setTimeout(() => {
              const form: HTMLFormElement | null = document.getElementById(
                "onetime-payment-form"
              ) as HTMLFormElement;
              if (form) {
                form.submit();
              }
            }, 500);
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [appId, paymentIntentId]);

  const renderBody = () => {
    if (!loading) {
      return (
        <>
          <i className="fa-solid fa-ghost display-2"></i>
          <h3 className="pt-3">Invalid request!</h3>
        </>
      );
    }

    return (
      <>
        <Spinner />
        <h3 className="pt-3">Your invoice is processing..</h3>
      </>
    );
  };

  const getEmail = () => {
    if (oneTimePayload) {
      if (oneTimePayload.customer.email?.length > 0) {
        return oneTimePayload.customer.email;
      }
      const nickName = oneTimePayload.customer.firstName.split(" ")[0];
      return `${nickName}@garlicbed.com`;
    }

    return "";
  };

  const renderForm = oneTimePayload && (
    <form
      action={oneTimeUrl}
      name="onetime-payment-form"
      id="onetime-payment-form"
      method="post"
      style={{ display: "none" }}
    >
      <input
        type="text"
        name="first_name"
        value={oneTimePayload.customer.firstName}
        readOnly
      />
      <input
        type="text"
        name="last_name"
        value={oneTimePayload.customer.lastName}
        readOnly
      />
      <input type="text" name="email" value={getEmail()} readOnly />
      <input
        type="text"
        name="contact_number"
        value={oneTimePayload.customer.contactNumber}
        readOnly
      />
      <input
        type="text"
        name="address_line_one"
        value={oneTimePayload.customer.address}
        readOnly
      />
      <input
        type="text"
        name="process_currency"
        value={oneTimePayload.currency}
        readOnly
      />

      <input
        type="hidden"
        name="secret_key"
        value={oneTimePayload.secretKey}
        readOnly
      />

      <input
        type="hidden"
        name="payment"
        value={oneTimePayload.payment}
        readOnly
      />
      <input
        type="hidden"
        name="custom_fields"
        value={oneTimePayload.customFields}
        readOnly
      />
    </form>
  );

  return (
    <div className="main-content py-7 full-screen-height">
      <AuthNavbar varient="light" />
      <Container className=" pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary shadow border-0 ">
              <CardBody className="px-lg-5 py-lg-5 align-center">
                {renderBody()}
                {renderForm}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OneTimePayments;
