import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Card, CardBody, Spinner } from "reactstrap";

import GARLIC_BED from "../assets/img/brand/GarlicBed.svg";
import { PaymentStatusType } from "../common/types";

const PaymentStatus = () => {
  const [status, setstatus] = useState<PaymentStatusType | null>(null);
  const query = new URLSearchParams(useLocation().search);
  const result3ds = query.get("result3ds");

  useEffect(() => {
    if (result3ds) {
      const result = atob(result3ds);
      setstatus(JSON.parse(result));
    }
  }, [result3ds]);

  const renderBody = () => {
    if (!status) {
      return (
        <>
          <Spinner />
          <h3 className="pt-3">Your invoice is processing..</h3>
        </>
      );
    } else if (status.success) {
      return (
        <>
          <i className="fa-solid fa-circle-check  success"></i>
          <h2>Payment Success!</h2>
          <section>
            <p>Order Id: {status.merchantProvidedOrderNumber} </p>
            <p>Receipt No: {status.receipt}</p>
          </section>
        </>
      );
    } else {
      return (
        <>
          <i className="fa-solid fa-circle-xmark failed"></i>
          <h2>Payment Failed!</h2>
          <section>
            <p>Order Id: {status.merchantProvidedOrderNumber} </p>
          </section>
        </>
      );
    }
  };

  return (
    <div>
      <div className="card-container">
        <div className="py-7">
          <Card className="bg-secondary shadow border-0 ">
            <CardBody className="  align-center payment-status">
              {renderBody()}
            </CardBody>
          </Card>
        </div>
        <div className="footer-bottom">
          <span>
            <img alt="garlicbed-icon" src={GARLIC_BED} />
            <h6>PAYMENTS</h6>
          </span>
          <p className="copy-rights">
            © 2023 Garlic Bed (PVT) LTD. All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaymentStatus;
