import { ACTIONS } from "./actionTypes";

export const getAllApps = () => ({ type: ACTIONS.GET_ALL_APPS });

export const createNewApp = (
  payload: string,
  success: (keys?: { privateKey: string; secretKey: string }) => void
) => ({
  type: ACTIONS.CREATE_NEW_APP,
  payload,
  success,
});

export const updateApp = (payload: { returnUrl: string; id: string }) => ({
  type: ACTIONS.UPDATE_APP,
  payload,
});

export const deleteApp = (payload: string, success: () => void) => ({
  type: ACTIONS.DELETE_APP,
  payload,
  success,
});
