import { request } from "../../lib/api";
import { ENDPOINTS, REQUEST_SERVICE } from "../constants";

export default class AppsAPI {
  static getAllApps = () =>
    request(REQUEST_SERVICE.APPS, ENDPOINTS.GET_ALL_APPS, "GET", null, true);

  static createNewApp = (payload: { name: string }) =>
    request(
      REQUEST_SERVICE.APPS,
      ENDPOINTS.CREATE_NEW_APP,
      "POST",
      payload,
      true
    );

  static updateApp = (payload: { returnUrl: string; id: string }) =>
    request(REQUEST_SERVICE.APPS, ENDPOINTS.UPDATE_APP, "PUT", payload, true);

  static deleteApp = (payload: string) =>
    request(
      REQUEST_SERVICE.APPS,
      ENDPOINTS.DELETE_APP,
      "DELETE",
      {
        id: payload,
      },
      true
    );
}
