import { ACTIONS } from "./actionTypes";

export const authSignIn = (
  payload: {
    userName: string;
    password: string;
  },
  success: (success: boolean) => void
) => ({
  type: ACTIONS.AUTH_SIGN_IN,
  payload,
  success,
});

export const authSignOut = (success: (success: boolean) => void) => ({
  type: ACTIONS.AUTH_SIGN_OUT,
  success,
});

export const unsetStatus = (payload: string) => ({
  type: ACTIONS.UNSET_STATUS,
  payload,
});
