import { ACTIONS } from "../actions/actionTypes";
import { CommonReducerType } from "../types";

const INITIAL_STATE: CommonReducerType = {
  loading: false,
};

const commonReducer = (
  state = INITIAL_STATE,
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: payload,
      };

    default:
      return state;
  }
};

export default commonReducer;
