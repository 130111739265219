export enum ACTIONS {
  //AUTH
  AUTH_SIGN_IN = "AUTH_SIGN_IN",
  AUTH_SIGN_OUT = "AUTH_SIGN_OUT",
  SET_AUTH = "SET_AUTH",
  SET_STATUS = "SET_STATUS",
  UNSET_STATUS = "UNSET_STATUS",

  //VENDORS
  FETCH_VENDORS = "FETCH_VENDORS",
  GET_VENDOR_BY_ID = "GET_VENDOR_BY_ID",
  SET_VENDORS = "SET_VENDORS",
  DELETE_VENDOR = "DELETE_VENDOR",

  //APPS
  SET_APPS = "SET_APPS",
  CREATE_NEW_APP = "CREATE_NEW_APP",
  GET_ALL_APPS = "GET_ALL_APPS",
  UPDATE_APP = "UPDATE_APP",
  DELETE_APP = "DELETE_APP",

  //COMMON
  SET_LOADING = "SET_LOADING",
}
