/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { Link } from "react-router-dom";
import { NavbarBrand, Navbar, Container } from "reactstrap";

import GARLIC_BED from "../../assets/img/brand/GarlicBed.svg";

type OwnTypes = {
  varient?: "dark" | "light";
};

const AdminNavbar = ({ varient = "dark" }: OwnTypes) => {
  return (
    <Navbar
      className={`navbar-top navbar-horizontal navbar-${varient}`}
      expand="md"
    >
      <Container className="px-4">
        <NavbarBrand to="/" tag={Link}>
          <img alt="garlicbed-icon" src={GARLIC_BED} /> Payments
        </NavbarBrand>
      </Container>
    </Navbar>
  );
};

export default AdminNavbar;
