import { Routes, BrowserRouter as Router, Route } from "react-router-dom";

import ProtectedRoute from "./auth/ProtectedRoute";

import AdminLayout from "./layouts/Admin";
import AuthLayout from "./layouts/Login";
import OneTimePayments from "./views/OneTimePayments.view";
import PaymentStatus from "./views/PaymentStatus.view";
import Home from "./views/Home.view";
import Tokenization from "./views/Tokenization.view";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/admin" element={<AuthLayout />} />
        <Route path="/one-time/payments" element={<OneTimePayments />} />
        <Route path="/checkout/status" element={<PaymentStatus />} />
        <Route path="/card/mobile" element={<Tokenization />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/admin/*" element={<AdminLayout />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
