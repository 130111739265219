/* eslint-disable no-empty-pattern */
import { call, put, select, takeLeading } from "redux-saga/effects";
import { ACTIONS } from "../actions/actionTypes";
import AppsAPI from "../api/apps.api";
import { AppsType, Reducers } from "../types";

function* getAllApps({}: { type: typeof ACTIONS.GET_ALL_APPS }) {
  try {
    yield put({ type: ACTIONS.SET_LOADING, payload: true });
    const data: { apps: AppsType[] } = yield call(AppsAPI.getAllApps);
    yield put({ type: ACTIONS.SET_APPS, payload: data.apps });
    yield put({ type: ACTIONS.SET_LOADING, payload: false });
  } catch (error) {
    yield put({ type: ACTIONS.SET_LOADING, payload: false });
  }
}

function* createNewApp({
  payload,
  success,
}: {
  type: typeof ACTIONS.AUTH_SIGN_IN;
  payload: string;
  success: (keys?: { privateKey: string; secretKey: string }) => void;
}) {
  try {
    const data: { success: boolean; credentials: AppsType } = yield call(
      AppsAPI.createNewApp,
      {
        name: payload,
      }
    );
    const apps: AppsType[] = yield select((state: Reducers) => state.apps.apps);
    const newApp: AppsType = {
      _id: data.credentials._id,
      name: data.credentials.name,
      appId: data.credentials.appId,
      appSecret: data.credentials.appSecret,
    };

    yield put({ type: ACTIONS.SET_APPS, payload: [newApp, ...apps] });
    success({
      privateKey: data.credentials.privateKey || "",
      secretKey: data.credentials.appSecret,
    });
  } catch (error: any) {}
}

function* updateApp({
  payload,
  success,
}: {
  type: typeof ACTIONS.UPDATE_APP;
  payload: { returnUrl: string; id: string };
  success: () => void;
}) {
  try {
    let apps: AppsType[] = yield select((state: Reducers) => state.apps.apps);
    yield put({ type: ACTIONS.SET_LOADING, payload: true });
    yield call(AppsAPI.updateApp, payload);
    const foundIndex = apps.findIndex((_app) => _app._id === payload.id);
    apps[foundIndex] = { ...apps[foundIndex], ...payload };
    yield put({
      type: ACTIONS.SET_APPS,
      payload: apps,
    });
    yield put({ type: ACTIONS.SET_LOADING, payload: false });
    success();
  } catch (error) {
    yield put({ type: ACTIONS.SET_LOADING, payload: false });
  }
}

function* deleteApp({
  payload,
  success,
}: {
  type: typeof ACTIONS.DELETE_APP;
  payload: string;
  success: () => void;
}) {
  try {
    const apps: AppsType[] = yield select((state: Reducers) => state.apps.apps);
    yield put({ type: ACTIONS.SET_LOADING, payload: true });
    yield call(AppsAPI.deleteApp, payload);
    yield put({
      type: ACTIONS.SET_APPS,
      payload: apps.filter((_app) => _app._id !== payload),
    });
    yield put({ type: ACTIONS.SET_LOADING, payload: false });
    success();
  } catch (error) {
    yield put({ type: ACTIONS.SET_LOADING, payload: false });
  }
}

export default function* appsSaga() {
  yield takeLeading(ACTIONS.CREATE_NEW_APP, createNewApp);
  yield takeLeading(ACTIONS.GET_ALL_APPS, getAllApps);
  yield takeLeading(ACTIONS.UPDATE_APP, updateApp);
  yield takeLeading(ACTIONS.DELETE_APP, deleteApp);
}
