import { ACTIONS } from "../actions/actionTypes";
import { AppsReducerType } from "../types";

const INITIAL_STATE: AppsReducerType = {
  apps: [],
};

const appsReducer = (
  state = INITIAL_STATE,
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case ACTIONS.SET_APPS:
      return {
        ...state,
        apps: payload,
      };

    default:
      return state;
  }
};

export default appsReducer;
