/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { Container, Row } from "reactstrap";

// core components
import AuthNavbar from "../components/Navbars/AuthNavbar.component";

import Login from "../views/examples/Login.view";

const Auth = () => {
  return (
    <div className="main-content bg-default py-7 full-screen-height">
      <AuthNavbar />
      <Container className=" pb-5">
        <Row className="justify-content-center">
          <Login />
        </Row>
      </Container>
    </div>
  );
};

export default Auth;
